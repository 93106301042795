<template>
  <div>
      <flixDashboard>
        <template v-slot:content>
          <div class="flix-container">
            <flixCheckPermissions role="customer" permission="2" error>
              <crm />
            </flixCheckPermissions>
          </div>
        </template>
      </flixDashboard>
  </div>
</template>

<script>
export default {
  components: {
    crm () { return import('@/components/crm/add') }
  }
}
</script>
